import React from 'react'
import Index from '../../components/hulu'

const hulu = (props) => {
  return (
    <>
      <Index {...props} />
    </>
  )
}
export default hulu;