import React from "react";
import Header from "./Header";
import Layout from '../Layout'
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import Film from "./Film";
import Schedule from "./Schedule";
import Audience from "./Audience";
import Budget from "./Budget";
import Air from "./Air";
import HorizontalScroll from "../HorizontalScroll";
import Contact from "../contact";

const Index = () => {

  const underlay = () => {
    return (
      <>
        <Background>
          <StaticImage
            src="../../images/media_hero.jpg"
            alt="Marketing Services"
            loading="eager"
          />
        </Background>
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech + Hulu Ad Manager - Advertising Solution for Streaming TV",
      desc: `Television commercials have long been affordable only for large companies with big marketing budgets. 
      However, the advent of streaming services has brought TV advertising within reach of even the most modest of 
      businesses. Hulu offers advertising at affordable rates. These ads can be targeted to specific demographics 
      and geographic areas. JL Tech can shoot and edit your commercial for a professional look and feel. 
      TV advertising is now within reach!.`,
      keywords: `photographers near me, videographers near me, social media manager near me, photographer spokane, 
      videographer spokane, 'photographer coeur d'alene, videographer coeur d'alene, social media spokane, 
      social media coeur d'alene, commercials, hulu ads, promotional clips, video editing`
    }} snap>
      <Header />
      <HorizontalScroll>
        <Film id="film" />
        <Schedule id="schedule" />
        <Audience id="audience" />
        <Budget id="budget" />
        <Air id="air" />
      </HorizontalScroll>
      <Chat />
    </Layout>
  );
};

export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;

const Background = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(65, 45, 219, .6) 0%, rgba(65, 45, 219, .95) 100%) 0% 0% no-repeat padding-box;
`;