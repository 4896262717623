import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import {CONTAINER, H2, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS, PATHS} from "../../constants";
import {StaticImage} from "gatsby-plugin-image";
import {AiFillSchedule} from "@react-icons/all-files/ai/AiFillSchedule";
import IconWithText from "../IconWithText";

const Schedule = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Icon size={40} icon={<AiFillSchedule />} />
        <Title>
          Schedule.
        </Title>
        <Content justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24}>
            <Paragraph>
              You set the dates you'd like to air your commercial.
            </Paragraph>
          </Col>
        </Content>
      </Container>
      <Background>
        <StaticImage
          src="../../images/design_hero.jpg"
          alt="Unified end to end solutions."
          loading="eager"
          transformOptions={{grayscale: true}}
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Schedule);

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 40vw;
    min-width: 40vw;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(26, 18, 88, .9) 0%, rgba(26, 18, 88, .9) 100%) 0% 0% no-repeat padding-box;
`;

const Content = styled(Row)`
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    overflow: visible;
  }
`;

const Icon = styled(IconWithText)`
  ${H2}
  margin-bottom: 50px;
`;

const Title = styled.h2`
  ${H2}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 50px;
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 50px;
`;