import React, {forwardRef} from "react";
import Hulu  from "./Logo";
import Section  from "../section";
import UsPlus from "../UsPlus";
import styled from "styled-components";
import {BREAKPOINTS} from "../../constants";
import {CONTAINER, D1, H1, H2, H3, H5, H6, LEADTEXT, LogoWrapper, PARAGRAPH} from "../../styles/Global";

const Header = (props, ref) => {
  return (
    <Section {...props} ref={ref}>
      <Container>
        <Content>
          <UsPlus logo={<Hulu />} />
          <Title>Everyone Is Streaming. You Could Be Too!</Title>
          <Paragraph>
            Custom filmed commercials air on Hulu starting at just $1,250.
          </Paragraph>
        </Content>
      </Container>
    </Section>
  );
};

export default forwardRef(Header);

const Container = styled.div`
  ${CONTAINER}
  padding: 0 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  ${H2}
  text-align: center;
  font-weight: bold ;
  letter-spacing: 0px;
  color: #f1f0f5;
  opacity: 1;
  padding-bottom: 30px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    ${H1}
    padding: 0 20px;
  }
`;

const Paragraph = styled.p`
  ${H5}
  color: #fff;
  text-align: center;
`;