import React from "react";
import { Logo } from "./Logo";
import styled from "styled-components";
import {BREAKPOINTS} from "../constants";
import {CONTAINER, D1, H1, PARAGRAPH} from "../styles/Global";
import {AiOutlinePlus} from "@react-icons/all-files/ai/AiOutlinePlus";

const UsPlus = ({logo, ...props}) => {
  return (
    <Wrapper {...props}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <PlusWrapper><AiOutlinePlus/></PlusWrapper>
        {logo ? (
          <LogoWrapper>
            {logo}
          </LogoWrapper>
        ) : null}
    </Wrapper>
  );
};

export default UsPlus;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 100px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    margin-bottom: 200px;
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 120px;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    max-width: 180px;
  }
  
  @media (min-width: ${BREAKPOINTS['4k']}px) {
    max-width: 6vw;
  }
  
  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const PlusWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 120px;
  font-size: 36px;
  color #fff;
  text-align: center;
`;